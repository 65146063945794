<template>
  <div class="back">
    <div class="fancy-text">
     <p style="padding:0px;margin: 50px; margin-bottom: 20px;">
       DESTROY
       DICK
       DECEMBER
      </p> 
      <p style="margin: 10px;margin-bottom: 40px;">
        Advent calendar
      </p>
    </div>
    <div class="grid-container">
      <div class="grid-item" v-for="n in 24" :key="n">
        <cardComponent :msg="lien[n]" :index="n"></cardComponent>
      </div>
    </div>

  </div>
</template>

<script>
import cardComponent from './card.vue'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data()
  {
    return{
      lien : ["dsqds" ,'https://www.bellesa.co/videos/3817/strangers-on-a-plane', 'https://www.bellesa.co/videos/8617/do-what-she-says', 'https://fr.pornhub.com/view_video.php?viewkey=64edda656cee9', 'https://www.bellesa.co/videos/8753/thirsty-client', 'https://www.bellesa.co/videos/1118/welcome-to-our-secret-sex-society', 'https://fr.pornhub.com/view_video.php?viewkey=ph63c3f63cf1f74', 'https://fr.pornhub.com/view_video.php?viewkey=653f83821c1e3', 'https://www.bellesa.co/videos/3656/better-than-breakfast', 'https://fr.pornhub.com/view_video.php?viewkey=6732db5972fb0', 'https://www.bellesa.co/videos/1860/ocean-fun', 'https://www.bellesa.co/videos/9726/american-milf-episode-5', 'https://www.bellesa.co/videos/9695/learn-the-ropes', 'https://fr.pornhub.com/view_video.php?viewkey=6683fa2bf3914', 'https://www.bellesa.co/videos/9752/off-the-climbing-walls', 'https://fr.pornhub.com/view_video.php?viewkey=66183152115e3', 'https://lelombrik.net/47887', 'https://www.bellesa.co/videos/6521/intimate-anal-massage', 'https://fr.pornhub.com/view_video.php?viewkey=667bce578ba36', 'https://www.bellesa.co/videos/9676/aubrey-for-dinner', 'https://fr.pornhub.com/view_video.php?viewkey=ph608bf18bdc4ba', 'https://www.porntrex.com/video/1909495/blondie-fesser-dining-on', 'https://fr.pornhub.com/view_video.php?viewkey=ph63a23ebcbeaad', 'https://fr.pornhub.com/view_video.php?viewkey=ph5f2c3e75a58d2', 'https://www.youtube.com/watch?v=dQw4w9WgXcQ']
  }}
  ,
  components: {
    cardComponent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.back{
  background-image: url('../../public/background.png'); /* Replace with the path to your image */
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  /* height: 160vh; /* Adjust the height as needed 
  width: 100vw; Adjust the width as needed */
  position: absolute;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.grid-container {
  display: grid;
  width : 70%;
  margin: auto;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 50px; /* Adjust the spacing between the grid items */
  padding: 10px;
}
.grid-item {
  border: 1px solid #000; /* Add border to each grid item */
  background-color: #fff;
  text-align: center;
  aspect-ratio: 1 / 1; /* Make the grid items square */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and box-shadow */

}

.grid-item:hover{
  transform: scale(1.1) rotate(-3deg);
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.8), /* Shining box effect */
              0 0 30px 15px rgba(255, 255, 255, 0.6),
              0 0 40px 20px rgba(255, 255, 255, 0.4);
}

.fancy-text {
  font-family: 'Impact', 'Arial Black', sans-serif; /* Sharp letters font */
  font-size: 96px; /* Oversized letters */
  color: red; /* Red font color */
  text-shadow: 3px 3px 0 #000, /* Black border effect */
               -1px -1px 0 #000,  
               1px -1px 0 #000,
               -1px 1px 0 #000,
               1px 1px 0 #000,
               4px 4px 10px rgba(0,0,0,0.5); /* Ugly 3D effect */
  text-align: center; /* Centers the text */
  margin-top: 20px; /* Adds some space at the top */
  perspective: 500px; /* Adds perspective */
  transform: rotateX(20deg) rotateY(30deg) rotateZ(-5deg);
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Adjust the spacing between the grid items */
  }
  .grid-item {
    aspect-ratio: 1 / 1; /* Make the grid items square */
  }
  .fancy-text {
    font-size: 48px; /* Oversized letters */
  }
  
}
</style>
