<template>
  <div class="all">
    <div class="nav">
      <button @click="show = true">DDD</button>
      <button @click="show = false">Petit quizz du cul</button>
    </div>
      <HelloWorld v-if="show" msg="Hello World!" />
      <QuizComponent v-else />


  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import QuizComponent from './components/quizComponent.vue';

export default {
  name: 'App',
  components: {
    HelloWorld,
    QuizComponent
  },
  data() {
    return {
      show: true
    };
  }
};
</script>

<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.nav {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 999;
  background: transparent;
}

.nav button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.nav button:hover {
  background-color: #45a049;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%; /* Add this line to set the width */
  height: 100%; /* Add this line to set the height */
}
</style>