import QuizComponent from "./components/quizComponent.vue";

import HelloWorld from "./components/HelloWorld.vue";

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HelloWorld
  },
  {
    path: '/lecul',
    name: 'lecul',
    component: QuizComponent
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;