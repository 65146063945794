<!-- src/components/NewComponent.vue -->
<template>
    <div class="quiz-container">
      <h1>Pour que nous puissions nous delecter de nos corps et nous adonner à nos pulsions</h1>
      <div class="input-container">
        <input v-model="newEntry" placeholder="Insérer un jouet" @keyup.enter="addEntry" />
        <button @click="addEntry">Ajouter</button>
      </div>
      <ul class="entries-list">
        <li v-for="(entry, index) in entries" :key="index" class="entry-item">
          {{ entry }}
          <button @click="removeEntry(index)" class="remove-button">Retirer</button>
        </li>
      </ul>
      <button @click="submitList" class="submit-button">Soumettre (le quiz, pas moi encore hehe)</button>
      <p v-if="result" class="result">{{ result }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NewComponent',
    data() {
      return {
        newEntry: '',
        entries: [],
        result: ''
      };
    },
    methods: {
      addEntry() {
        if (this.newEntry.trim() !== '') {
          this.entries.push(this.newEntry.trim());
          this.newEntry = '';
        }
      },
      removeEntry(index) {
        this.entries.splice(index, 1);
      },
      submitList() {
      if (this.entries.length > 0) {
        const elementToCheck = 'plug anal';
        const elementToCheck2 = 'boules giesha';
        const threshold = 2; // Set a threshold for Levenshtein distance

        const sanitizeString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');

        const lowerCaseEntries = this.entries.map(entry => sanitizeString(entry));
        const lowerCaseElementToCheck = sanitizeString(elementToCheck);
        const lowerCaseElementToCheck2 = sanitizeString(elementToCheck2);

        const levenshteinDistance = (a, b) => {
          const matrix = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));
          for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
          for (let j = 0; j <= b.length; j++) matrix[0][j] = j;
          for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
              matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1,
                matrix[i][j - 1] + 1,
                matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
              );
            }
          }
          return matrix[a.length][b.length];
        };

        const isMatch = (entry, element) => levenshteinDistance(entry, element) <= threshold;

        const matchElementToCheck = lowerCaseEntries.some(entry => isMatch(entry, lowerCaseElementToCheck));
        const matchElementToCheck2 = lowerCaseEntries.some(entry => isMatch(entry, lowerCaseElementToCheck2));

        if (matchElementToCheck && matchElementToCheck2) {
          this.result = `La couleur est violette Jade <3`;
        } else if (matchElementToCheck) {
          this.result = `Ta couleur est rouge Jadou, coquine, je risque de te chauffer comme a Amsterdam !`;
        } else if (matchElementToCheck2) {
          this.result = `Ta couleur est Vert Jadou, petite coquine`;
        } else {
          this.result = `Ta couleur est Bleu jadou ! <3`;
        }
      } else {
        this.result = 'La liste est vide wesh';
      }
    }
  }
  };
  </script>
  
  <style scoped>
  .quiz-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    outline: none;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .entries-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .entry-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .remove-button:hover {
    background-color: #c82333;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  .result {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
  }
  
  @media (max-width: 600px) {
    .quiz-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 20px;
    }
  
    input, button {
      font-size: 14px;
    }
  
    .entry-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .remove-button {
      margin-top: 10px;
    }
  }
  </style>