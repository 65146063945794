<template>
 <div style="height:100%;width:100%;">
    <div class="card-container">
      <div :class="['', { 'card': isPastDate }]">
        <span class="index">{{ index }}</span>
        <img src="lutin.png" alt="Image" class="card-image"/>
        <div class="card-content">
          <a :href="msg">
            <img class="content-image" src="perno.jpg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'cardComponent',
    props: {
        msg: String,
        index : Number
    },
    computed: {
    isPastDate() {
      const today = new Date().getDate();
      console.log(today);
      return this.index <= today;
    }
  }
}
</script>
<style scoped>

.content-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
}
.card-container {
  position: relative;
 /* Adjust the height as needed */
  overflow: hidden;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Add perspective for 3D effect */
}

.card-image {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform-origin: left; /* Rotate from the left side */
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.card:hover .card-image {
  transform: rotateY(-90deg); /* Rotate like a door opening */
}

.card:hover .card-content {
  opacity: 1;
}


.index {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 72px;
  z-index: 6;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .index {
    font-size: 36px;
  }
  
}
</style>